var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.flashcards,"sort-by":"calories"},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text},[_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]),_c('v-icon',{staticClass:"no-rotate",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("filter_list")]),_vm._v(" "+_vm._s(header.text)+" ")],1)}),0)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_c('h5',[_vm._v("Thẻ học từ vựng")])]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.addNew}},[_vm._v("Từ vựng mới ")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.audio",fn:function(ref){
var item = ref.item;
return [(item.audio.src)?_c('audio',{ref:"audio",staticClass:"audio-block",attrs:{"controls":"controls"}},[_c('source',{attrs:{"src":item.audio.src,"type":"audio/mpeg"}})]):_vm._e()]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.images.src,"alt":"","width":"80","height":"120"}})]}}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('ValidationObserver',{ref:"new_flashcard",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"check-login-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleUpload)}}},[_c('v-row',[_c('ValidationProvider',{attrs:{"rules":"required|minmax:0,10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Từ vựng"},model:{value:(_vm.editedItem.word),callback:function ($$v) {_vm.$set(_vm.editedItem, "word", $$v)},expression:"editedItem.word"}})],1),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Ví dụ"},model:{value:(_vm.editedItem.example),callback:function ($$v) {_vm.$set(_vm.editedItem, "example", $$v)},expression:"editedItem.example"}})],1),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Dịch nghĩa"},model:{value:(_vm.editedItem.translate),callback:function ($$v) {_vm.$set(_vm.editedItem, "translate", $$v)},expression:"editedItem.translate"}})],1),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Ghi chú"},model:{value:(_vm.editedItem.note),callback:function ($$v) {_vm.$set(_vm.editedItem, "note", $$v)},expression:"editedItem.note"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{staticClass:"pl-1",attrs:{"prepend-icon":"mdi-camera","accept":"image/png, image/jpeg, image/bmp","label":"Tải ảnh lên"},on:{"change":_vm.onChangeFileUpload,"click:clear":_vm.clearImage},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('div',{staticStyle:{"width":"200px"},attrs:{"id":"preview"}},[(_vm.cardImg)?_c('v-img',{attrs:{"src":_vm.cardImg}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{staticClass:"pl-1",attrs:{"prepend-icon":"mdi-file-music-outline","accept":".mp3,audio/*","label":"Tải audio lên mp3"},on:{"change":_vm.onChangeAudioUpload,"click:clear":_vm.clearAudio},model:{value:(_vm.audio),callback:function ($$v) {_vm.audio=$$v},expression:"audio"}}),_c('div',[(_vm.cardAudio)?_c('audio',{ref:"audio",staticClass:"audio-block",attrs:{"controls":"controls"}},[_c('source',{attrs:{"src":_vm.cardAudio,"type":"audio/mpeg"}}),_vm._v(" Your browser does not support the audio element. ")]):_vm._e()])],1)],1)],1)]}}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Đóng ")]),_c('v-btn',{attrs:{"type":"submit","form":"check-login-form","color":"blue darken-1","text":""}},[_vm._v("Lưu ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }